import React from 'react';

import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

import { useSiteMetadata } from '@hooks/queries';

type HeadProps = {
    title?: string;
    description?: string;
    image?: string;
};

const Head: React.FC<HeadProps> = ({ title, description, image }) => {
    const { pathname } = useLocation();

    const { defaultTitle, defaultDescription, siteUrl, defaultImage } =
        useSiteMetadata();

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
    };

    return (
        <Helmet
            title={title}
            defaultTitle={seo.title}
            titleTemplate={`%s | ${defaultTitle}`}
        >
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />
            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content="website" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
        </Helmet>
    );
};

export default Head;
