import React, { useEffect, useRef } from 'react';

import Player from '@vimeo/player';
import styled from 'styled-components';

export type IframeProps = {
    id: string;
    autoplay?: boolean;
};

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 75%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
`;

const Iframe: React.FC<IframeProps> = ({ id, autoplay }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const handlePlay = async () => {
        if (iframeRef.current === null) {
            return;
        }
        const player = new Player(iframeRef.current);
        const playPromise = player.play();
        if (playPromise !== null) {
            playPromise.catch(() => {});
        }
    };

    useEffect(() => {
        const options = {
            rootMargin: '-50% 0%',
        };

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    handlePlay();
                }
            });
        }, options);
        if (iframeRef.current === null) {
            return;
        }
        observer.observe(iframeRef.current);
    }, []);

    return (
        <Wrapper>
            <iframe
                ref={iframeRef}
                src={`https://player.vimeo.com/video/${id}?mute=1${
                    autoplay ? '&autoplay=1' : ''
                }&loop=1&title=false&byline=false&portrait=false`}
                width="550"
                height="370"
                loading="lazy"
                frameBorder="0"
                allow="fullscreen; autoplay"
            ></iframe>
        </Wrapper>
    );
};

export default Iframe;
