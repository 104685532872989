import React, { useEffect, useMemo, useState } from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';

import { ContentPost } from 'src/types';

import Book from './Book';

const Container = styled.div<{ active?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    min-height: 50vh;
`;

type PostsListProps = {
    posts: ContentPost[];
};

const PostsList: React.FC<PostsListProps> = ({ posts }) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);
    }, []);

    return (
        <Container active={animate}>
            {posts.map((post, index) => (
                <Link key={index} to={`/blog/${post.slug.replace(/\s/g, '')}`}>
                    <Book content={post} delay={`${index * 150 + 200}ms`} />
                </Link>
            ))}
        </Container>
    );
};

export default PostsList;
