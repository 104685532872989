import React from 'react';

import {
    ContentProjectShort,
    ExternalLinksNode,
    NavbarStateContent,
} from 'src/types';

type Action = { type: 'switch' } | { type: 'close' };
type Dispatch = (action: Action) => void;
type NavItem = {
    name: 'Projects' | 'Blog' | 'About' | 'Contact';
    href: string;
    content?: ContentProjectShort[];
};
type State = {
    isOpen: boolean;
    links: ExternalLinksNode;
    navigation: NavItem[];
};
type NavDataProviderProps = {
    children: React.ReactNode;
    content: NavbarStateContent;
};

const NavContext = React.createContext<
    { state: State; dispatch: Dispatch } | undefined
>(undefined);

const navDataReducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'close': {
            return { ...state, isOpen: false };
        }
        case 'switch': {
            return { ...state, isOpen: !state.isOpen };
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
};

const NavDataProvider = ({ children, content }: NavDataProviderProps) => {
    const { projects, links } = content;
    const [state, dispatch] = React.useReducer(navDataReducer, {
        isOpen: false,
        links,
        navigation: [
            { name: 'Projects', href: '/#projects', content: projects },
            { name: 'Blog', href: '/blog' },
            { name: 'About', href: '/#about' },
            { name: 'Contact', href: '#contact' },
        ],
    });

    const value = React.useMemo(
        () => ({
            state,
            dispatch,
        }),
        [state, dispatch]
    );

    return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

const useNav = () => {
    const context = React.useContext(NavContext);

    if (!context) {
        throw new Error('useNavData must be used within a NavDataProvider');
    }
    return context;
};

export { NavDataProvider, useNav };
