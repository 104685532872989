import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useForm, FieldValues, SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';

import Button from '@components/Button';
import Loader from '@components/Loader';
import { useSlider } from '@components/Slider';
import { device } from '@styles';
import { formSchema } from '@utils';

import ValidationMessage from './ValidationMessage';

const StyledForm = styled.form`
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: inherit;

    @media ${device.tablet} {
        padding: 0px;
    }
`;

const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 5px;

    & > input,
    textarea {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        background: inherit;
        border: 1px solid var(--white);
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        resize: none;
        color: var(--white);
        font-family: var(--font-sans) !important;
    }

    & > textarea {
        grid-row: 2;
        grid-column: span 2;
        min-height: 100px;
        overflow-y: hidden;

        @media ${device.mobileL} {
            grid-row: unset;
            grid-column: unset;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--white);
        transition: background-color 5000s ease-in-out 0s;
    }

    @media ${device.mobileL} {
        grid-template-columns: 1fr;
    }
`;

type FormProps = {
    setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

const API_URL = 'https://localhost:3000/contact';
const defaultValues = {
    email: '',
    name: '',
    message: '',
};

const Form: React.FC<FormProps> = ({ setSuccess }) => {
    const [loading, setLoading] = useState(false);
    const { nextStep } = useSlider();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitted },
    } = useForm({ resolver: yupResolver(formSchema) });

    const onSubmit: SubmitHandler<FieldValues> = async data => {
        setLoading(true);

        try {
            await axios.post(API_URL, data, {
                headers: { Accept: 'application/json' },
            });

            setSuccess(true);
        } catch (err) {
            setSuccess(false);
        } finally {
            setLoading(false);
            nextStep();

            reset(defaultValues);
        }
    };

    const { email, message, name } = errors || {};
    const isValid = Object.entries(errors).length === 0;

    return (
        <StyledForm
            acceptCharset="UTF-8"
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
            noValidate
        >
            <Grid>
                <input
                    type="email"
                    placeholder="Your email..."
                    {...register('email')}
                />
                <input
                    type="text"
                    placeholder="Your name..."
                    {...register('name')}
                />
                <textarea
                    placeholder="Your message..."
                    {...register('message')}
                />
            </Grid>
            {!isValid && isSubmitted && (
                <ValidationMessage fields={{ email, message, name }} />
            )}
            {loading ? (
                <Loader r={40} />
            ) : (
                <Button pure square type="submit">
                    Sent
                </Button>
            )}
        </StyledForm>
    );
};
export default Form;
