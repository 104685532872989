import React from 'react';

import Highlight, {
    defaultProps,
    Language,
    PrismTheme,
} from 'prism-react-renderer';

import { PrismStyles } from '@styles';

type CodeProps = {
    codeString: string;
    language: Language;
    theme: PrismTheme | undefined;
};

const Code: React.FC<CodeProps> = ({ codeString, language, ...props }) => (
    <Highlight
        {...defaultProps}
        code={codeString}
        language={language}
        theme={PrismStyles}
    >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <div className="gatsby-highlight" data-language={language}>
                <pre className={className} style={style}>
                    {tokens.map((line, i) => (
                        <div {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                            ))}
                        </div>
                    ))}
                </pre>
            </div>
        )}
    </Highlight>
);

export default Code;
