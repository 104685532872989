import { graphql, useStaticQuery } from 'gatsby';

import type { ProjectNode } from 'src/types';

const useOtherProjectsQuery = () => {
    const { projects } = useStaticQuery(graphql`
        {
            projects: allMdx(
                filter: {
                    frontmatter: { featured: { eq: false } }
                    internal: { contentFilePath: { regex: "/projects/other/" } }
                }
                sort: { fields: frontmatter___date, order: DESC }
            ) {
                nodes {
                    frontmatter {
                        name: slug
                        icon
                        img {
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                        iframe
                        quickDesc
                        description
                        tech
                        linkIcons
                        links
                    }
                }
            }
        }
    `);

    const content: ProjectNode[] = projects.nodes.map(
        (node: { frontmatter: ProjectNode }) => node.frontmatter
    );

    return content;
};

export default useOtherProjectsQuery;
