import { css } from 'styled-components';

const prismColors = {
    bg: `hsl(220, 13%, 18%)`,
    lineHighlight: `hsl(218, 13%, 24%)`,
    blue: `#5ccfe6`,
    purple: `#c3a6ff`,
    green: `#bae67e`,
    yellow: `#ffd580`,
    orange: `#ffae57`,
    red: `#ef6b73`,
    grey: `#a2aabc`,
    comment: `#8695b799`,
};

// https://www.gatsbyjs.org/packages/gatsby-remark-prismjs

const PrismStyles = css`
    blockquote {
        border-left-color: var(--orange);
        border-left-style: solid;
        border-left-width: 1px;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 1.5rem;
    }

    strong {
        color: var(--white);
    }

    .gatsby-highlight {
        background-color: ${prismColors.bg};
        border-radius: 4px;
        padding: 2em 1.25em;
        position: relative;
        overflow: auto;
    }

    .gatsby-highlight code[class*='language-'],
    .gatsby-highlight pre[class*='language-'] {
        font-size: 14px;
        line-height: 1.5;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        tab-size: 2;
        hyphens: none;
    }

    /* Language badges */
    .gatsby-highlight pre[class*='language-']::before {
        background: #233554;
        color: #e6f1ff;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        border-radius: 0 0 3px 3px;
        position: absolute;
        top: 0;
        left: 1.25rem;
        padding: 0.25rem 0.5rem;
    }
    .gatsby-highlight pre[class~='language-javascript']::before {
        content: 'js';
    }
    .gatsby-highlight pre[class~='language-js']::before {
        content: 'js';
    }
    .gatsby-highlight pre[class~='language-jsx']::before {
        content: 'jsx';
    }
    .gatsby-highlight pre[class~='language-graphql']::before {
        content: 'GraphQL';
    }
    .gatsby-highlight pre[class~='language-html']::before {
        content: 'html';
    }
    .gatsby-highlight pre[class~='language-css']::before {
        content: 'css';
    }
    .gatsby-highlight pre[class~='language-code']::before {
        content: 'code';
    }
    .gatsby-highlight pre[class~='language-mdx']::before {
        content: 'mdx';
    }
    .gatsby-highlight pre[class~='language-shell']::before {
        content: 'shell';
    }
    .gatsby-highlight pre[class~='language-sh']::before {
        content: 'sh';
    }
    .gatsby-highlight pre[class~='language-bash']::before {
        content: 'bash';
    }
    .gatsby-highlight pre[class~='language-yaml']::before {
        content: 'yaml';
    }
    .gatsby-highlight pre[class~='language-markdown']::before {
        content: 'md';
    }
    .gatsby-highlight pre[class~='language-json']::before,
    .gatsby-highlight pre[class~='language-json5']::before {
        content: 'json';
    }
    .gatsby-highlight pre[class~='language-diff']::before {
        content: 'diff';
    }
    .gatsby-highlight pre[class~='language-text']::before {
        content: 'text';
    }
    .gatsby-highlight pre[class~='language-flow']::before {
        content: 'flow';
    }
`;

export default PrismStyles;
