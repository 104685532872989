import React from 'react';

import { FieldError, Merge, FieldErrorsImpl } from 'react-hook-form';
import styled from 'styled-components';

const StyledErrorMessages = styled.div`
    font-size: 14px;
    margin: 10px 0 0 0;
    color: var(--p-color);
    padding: 0 5px;

    & > * {
        display: inline-block;
    }
`;

type ValidationMessageProps = {
    fields: {
        [error in 'email' | 'message' | 'name']?:
            | FieldError
            | Merge<FieldError, FieldErrorsImpl<any>>
            | undefined;
    };
};

const ValidationMessage: React.FC<ValidationMessageProps> = ({
    fields: { email, name, message },
}) => (
    <StyledErrorMessages>
        Hey, before you send: provide{' '}
        {email &&
            (email.type === 'required' ? (
                <>
                    an <h4>e-mail</h4>
                </>
            ) : (
                <>
                    a <h4>correct e-mail</h4>
                </>
            ))}
        {name && (
            <>
                {email && message && ', '}
                {email && !message && ', and '}
                your <h4>name</h4>
            </>
        )}
        {message && (
            <>
                {(email || name) && ', and '}a <h4>message</h4>
            </>
        )}
        .
    </StyledErrorMessages>
);

export default ValidationMessage;
