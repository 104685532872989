import React from 'react';

import styled from 'styled-components';

import Icon from '@components/Icon';
import type { SideBarProps } from 'src/types';

const Wrapper = styled.div`
    position: relative;
    right: -10px;
    z-index: 5;
    a {
        padding: var(--nav-logo-icons-padding);
        &:not(:last-of-type):after {
            content: '';
            position: absolute;
            width: 1px;
            margin-left: 10px;
            height: 100%;
            background-color: var(--white);
            opacity: 0.6;
        }
        svg {
            height: 18px;
            width: 18px;
        }
        &:hover {
            color: var(--orange);
        }
    }
`;

export const Links: React.FC<SideBarProps> = ({ content }) => {
    const { linkIcons, links } = content;
    return (
        <Wrapper>
            {links.map((item, i) => (
                <a
                    aria-label={linkIcons[i]}
                    key={i}
                    href={item}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Icon name={linkIcons[i]} />
                </a>
            ))}
        </Wrapper>
    );
};

export default Links;
