import React from 'react';

const CubeContact = () => (
    <svg
        width="59"
        height="30"
        viewBox="0 0 59 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M29.5 0L17 6.61765L23.145 9.66386L29.5 12.9202L35.75 9.66386L42 6.30252L35.75 3.09874L29.5 0Z"
            fill="#9E9E9E"
            fillOpacity="0.8"
        />
        <path
            d="M29.5 17.0798L23.25 20.4412L17 23.6975L23.25 26.7437L29.5 29.7899L35.75 26.7437L42 23.2773L35.75 20.1786L29.5 17.0798Z"
            fill="#FF9100"
            fillOpacity="0.8"
        />
        <path
            d="M46 8.38234L39.75 11.7437L33.5 15L39.75 18.0462L46 21.0924L52.25 18.0462L58.5 14.5798L52.25 11.4811L46 8.38234Z"
            fill="#FFC400"
            fillOpacity="0.8"
        />
        <path
            d="M12.5 8.80251L6.25 12.1639L0 15.4202L6.25 18.4664L12.5 21.5126L18.75 18.4664L25 15L18.75 11.9013L12.5 8.80251Z"
            fill="#616161"
            fillOpacity="0.8"
        />
    </svg>
);

export default CubeContact;
