import React from 'react';

const Figma = () => (
    <svg
        width="113"
        height="42"
        viewBox="0 0 113 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.9999 22C15.9999 20.4087 16.6321 18.8826 17.7573 17.7574C18.8825 16.6321 20.4086 16 21.9999 16C23.5912 16 25.1174 16.6321 26.2426 17.7574C27.3678 18.8826 27.9999 20.4087 27.9999 22C27.9999 23.5913 27.3678 25.1174 26.2426 26.2426C25.1174 27.3679 23.5912 28 21.9999 28C20.4086 28 18.8825 27.3679 17.7573 26.2426C16.6321 25.1174 15.9999 23.5913 15.9999 22V22Z"
            stroke="#CCCCCC"
            strokeWidth="3.5"
        />
        <path
            d="M4 34C4 32.4087 4.63214 30.8826 5.75736 29.7574C6.88258 28.6321 8.4087 28 10 28H16V34C16 35.5913 15.3679 37.1174 14.2426 38.2426C13.1174 39.3679 11.5913 40 10 40C8.4087 40 6.88258 39.3679 5.75736 38.2426C4.63214 37.1174 4 35.5913 4 34Z"
            stroke="#CCCCCC"
            strokeWidth="3.5"
        />
        <path
            d="M15.9999 4V16H21.9999C23.5912 16 25.1174 15.3679 26.2426 14.2426C27.3678 13.1174 27.9999 11.5913 27.9999 10C27.9999 8.4087 27.3678 6.88258 26.2426 5.75736C25.1174 4.63214 23.5912 4 21.9999 4H15.9999Z"
            stroke="#CCCCCC"
            strokeWidth="3.5"
        />
        <path
            d="M4 10C4 11.5913 4.63214 13.1174 5.75736 14.2426C6.88258 15.3679 8.4087 16 10 16H16V4H10C8.4087 4 6.88258 4.63214 5.75736 5.75736C4.63214 6.88258 4 8.4087 4 10Z"
            stroke="#CCCCCC"
            strokeWidth="3.5"
        />
        <path
            d="M4 22C4 23.5913 4.63214 25.1174 5.75736 26.2426C6.88258 27.3679 8.4087 28 10 28H16V16H10C8.4087 16 6.88258 16.6321 5.75736 17.7574C4.63214 18.8826 4 20.4087 4 22Z"
            stroke="#CCCCCC"
            strokeWidth="3.5"
        />
        <g clipPath="url(#clip0_345_111)">
            <path
                d="M41.5312 8.79077V28.4819L44.0707 28.4917V19.115H50.1754V16.634H44.061V11.2619H52.0214V8.79077H41.5312ZM56.5731 12.7954C57.0134 12.7954 57.4358 12.6205 57.7472 12.3091C58.0586 11.9977 58.2335 11.5753 58.2335 11.135C58.2335 10.6946 58.0586 10.2722 57.7472 9.96083C57.4358 9.64943 57.0134 9.47449 56.5731 9.47449C56.1327 9.47449 55.7103 9.64943 55.3989 9.96083C55.0875 10.2722 54.9126 10.6946 54.9126 11.135C54.9126 11.5753 55.0875 11.9977 55.3989 12.3091C55.7103 12.6205 56.1327 12.7954 56.5731 12.7954V12.7954ZM55.2935 15.2666V28.4917H57.833V15.2666H55.2935V15.2666Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.2195 14.8857C63.8595 14.8857 61.5153 17.728 61.5153 21.1075C61.5153 24.487 63.8595 27.3294 67.2195 27.3294C68.7628 27.3294 70.0325 26.7433 70.9604 25.7666V27.5247C70.9669 28.05 70.8692 28.5714 70.6729 29.0588C70.4766 29.5461 70.1857 29.9897 69.8169 30.3639C69.4482 30.7381 69.0089 31.0355 68.5245 31.2389C68.0401 31.4423 67.5202 31.5477 66.9949 31.5489C66.1842 31.5489 65.3832 31.2363 64.7483 30.787L63.4786 32.9847C64.4604 33.6194 65.5954 33.9773 66.7637 34.0207C67.932 34.064 69.0903 33.7913 70.1165 33.2312C71.1427 32.6711 71.9987 31.8444 72.5942 30.8383C73.1897 29.8322 73.5026 28.6841 73.5 27.515V15.2763H70.9604V16.4582C70.0325 15.4815 68.7628 14.8954 67.2195 14.8954V14.8857ZM64.0451 21.1075C64.0451 19.0075 65.6469 17.3568 67.5028 17.3568C69.3586 17.3568 70.9507 19.0173 70.9507 21.1075C70.9507 23.2075 69.3488 24.8484 67.5028 24.8484C65.6469 24.8484 64.0451 23.2075 64.0451 21.1075Z"
                fill="white"
            />
            <path
                d="M82.6228 17.3568C81.8965 17.3697 81.2044 17.6679 80.6963 18.187C80.1882 18.7061 79.9048 19.4044 79.9075 20.1308V28.4917H77.3679V15.2666H79.9075V16.4778C80.2874 15.9742 80.7809 15.5675 81.3479 15.2908C81.9148 15.0142 82.539 14.8753 83.1698 14.8857C84.9475 14.8857 86.3149 15.7647 87.0963 17.1126C87.5322 16.4369 88.1289 15.8798 88.8328 15.4912C89.5368 15.1026 90.3262 14.8945 91.1302 14.8857C94.0116 14.8857 95.8186 17.2103 95.8186 20.0917V28.4917H93.2986V20.121C93.3013 19.3946 93.0179 18.6963 92.5098 18.1772C92.0016 17.6581 91.3096 17.3599 90.5833 17.3471C89.857 17.3599 89.1649 17.6581 88.6568 18.1772C88.1486 18.6963 87.8652 19.3946 87.8679 20.121V28.4819H85.3284V20.121C85.3311 19.3963 85.0491 18.6994 84.543 18.1806C84.037 17.6618 83.3474 17.3624 82.6228 17.3471V17.3568Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M109.327 16.7708C108.75 16.1694 108.057 15.6921 107.289 15.368C106.522 15.0438 105.696 14.8797 104.863 14.8857C101.093 14.8857 98.4167 18.0698 98.4167 21.8791C98.4167 25.6884 101.093 28.8726 104.863 28.8726C105.696 28.8769 106.521 28.712 107.288 28.3879C108.056 28.0639 108.749 27.5875 109.327 26.9875V28.4917H111.857V15.2666H109.317V16.7708H109.327ZM100.947 21.8791C100.947 19.3591 102.88 17.3568 105.137 17.3568C107.383 17.3568 109.327 19.3591 109.327 21.8791C109.327 24.3991 107.393 26.3917 105.137 26.3917C102.89 26.3917 100.947 24.3991 100.947 21.8791V21.8791Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_345_111">
                <rect
                    width="71.3023"
                    height="25.3953"
                    fill="white"
                    transform="translate(41.0233 8.79077)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default Figma;
