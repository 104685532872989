import React from 'react';

import styled from 'styled-components';

import { device } from '@styles';
import { TagContent } from 'src/types';

import List from './List';

const StyledSideBar = styled.div`
    position: relative;
    max-width: 175px;
    padding-right: auto;

    @media ${device.tablet} {
        display: none;
    }
`;

type SideBarProps = {
    tags: TagContent[];
    withContent: boolean;
    sections: HTMLElement[] | undefined;
    title: string | undefined;
};
const SideBar: React.FC<SideBarProps> = ({
    tags,
    withContent,
    sections,
    title,
}) => {
    const sectionsContent = sections?.map(header => {
        const data = {
            value: header.id,
        };
        return data;
    });

    return (
        <StyledSideBar>
            <List title="Blog categories" list={tags} />
            {withContent && (
                <List
                    title="Table of content"
                    list={sectionsContent}
                    table
                    post={title}
                />
            )}
        </StyledSideBar>
    );
};

export default SideBar;
