import React, { useState } from 'react';

import styled from 'styled-components';

const StyledSlider = styled.div`
    overflow: hidden;
`;

const Inner = styled.div<{ step: number }>`
    display: flex;
    will-change: transform;
    transform: ${({ step }) => `translateX(${step * -100}%)`};
    transition: transform 250ms ease 0s;

    & > * {
        width: 100%;
        flex-shrink: 0;
    }
`;

type SliderProps = {
    children: React.ReactNode[];
};

const SliderContext = React.createContext<
    { nextStep: () => void; step: number } | undefined
>(undefined);

const Slider = ({ children }: SliderProps) => {
    const steps = children.length;
    const [step, setStep] = useState<number>(0);

    const nextStep = () => {
        if (step < steps - 1) {
            setStep(prevStep => prevStep + 1);
        } else {
            setStep(0);
        }
    };

    const value = {
        nextStep,
        step,
    };

    return (
        <StyledSlider>
            <Inner step={step}>
                <SliderContext.Provider value={value}>
                    {children}
                </SliderContext.Provider>
            </Inner>
        </StyledSlider>
    );
};

const useSlider = () => {
    const context = React.useContext(SliderContext);

    if (!context) {
        throw new Error('useSlider must be used within a Slider');
    }
    return context;
};

export { Slider, useSlider };
