import React from 'react';

import styled from 'styled-components';

import { useNav } from '@hooks';
import { device } from '@styles';

import NavItem from './Item';

const Wrapper = styled.ul<{ active: boolean }>`
    display: flex;
    position: absolute;
    justify-content: center;
    left: 0;
    right: 0;
    margin: 0 auto;

    @media ${device.tablet} {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin: 0;
        align-items: center;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: var(--nav-height);
        right: ${props => (props.active ? 0 : '-120%')};
        left: auto;
        opacity: 1;
        background: ${props => (props.active ? 'var(--black)' : '')};
        transition: ${props => (props.active ? 'all 0.5s ease' : '')};
    }
`;

export const Navigation: React.FC = () => {
    const { state } = useNav();
    const { navigation, isOpen } = state;

    return (
        <Wrapper active={isOpen}>
            {navigation.map((item, index) => (
                <NavItem key={index} index={index} />
            ))}
        </Wrapper>
    );
};

export default Navigation;
