import React from 'react';

const IconReact = () => (
    <svg
        width="106"
        height="36"
        viewBox="0 0 106 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.2157 21.5512C22.1956 21.5512 23.8006 19.9462 23.8006 17.9663C23.8006 15.9865 22.1956 14.3815 20.2157 14.3815C18.2359 14.3815 16.6309 15.9865 16.6309 17.9663C16.6309 19.9462 18.2359 21.5512 20.2157 21.5512Z"
            fill="white"
            fillOpacity="0.9"
        />
        <path
            d="M20.2158 10.6776C25.0289 10.6776 29.5002 11.3682 32.8715 12.5288C36.9335 13.9273 39.431 16.0469 39.431 17.9663C39.431 19.9664 36.7842 22.2183 32.4221 23.6635C29.1244 24.7561 24.7847 25.3265 20.2158 25.3265C15.5314 25.3265 11.0956 24.7911 7.76066 23.6515C3.54134 22.2093 1 19.9283 1 17.9663C1 16.0625 3.38456 13.9592 7.38943 12.5629C10.7733 11.3831 15.355 10.6776 20.2152 10.6776H20.2158Z"
            stroke="white"
            strokeOpacity="0.9"
            strokeWidth="1.66272"
        />
        <path
            d="M13.8708 14.3429C16.2753 10.1735 19.1072 6.64519 21.7969 4.30451C25.0375 1.48434 28.1214 0.379881 29.7841 1.33863C31.5168 2.33783 32.1452 5.75575 31.218 10.2563C30.5177 13.659 28.8432 17.7031 26.5607 21.661C24.2204 25.719 21.5407 29.2942 18.8877 31.6132C15.5306 34.5478 12.285 35.6098 10.5853 34.6296C8.93607 33.6792 8.30538 30.5622 9.09657 26.3953C9.76506 22.8745 11.4427 18.5531 13.87 14.3429L13.8708 14.3429Z"
            stroke="white"
            strokeOpacity="0.9"
            strokeWidth="1.66272"
        />
        <path
            d="M13.8768 21.6717C11.4654 17.5069 9.82366 13.2906 9.13837 9.79139C8.31346 5.57536 8.8967 2.35188 10.5578 1.39034C12.2888 0.388213 15.5638 1.55069 18.9999 4.60167C21.5978 6.90834 24.2655 10.3781 26.5547 14.3323C28.9016 18.3864 30.6606 22.4936 31.3449 25.9502C32.2108 30.3243 31.51 33.6665 29.8119 34.6495C28.1643 35.6033 25.1493 34.5934 21.9344 31.8269C19.218 29.4895 16.3119 25.8778 13.8768 21.6716V21.6717Z"
            stroke="white"
            strokeOpacity="0.9"
            strokeWidth="1.66272"
        />
        <path
            d="M60.452 24.792L56.192 18.356C58.345 18.166 60.285 16.606 60.285 13.756C60.285 10.856 58.227 9 55.34 9H49V24.78H50.964V18.51H54.111L58.133 24.78L60.452 24.792ZM55.105 16.795H50.965V10.762H55.105C56.975 10.762 58.252 11.992 58.252 13.767C58.252 15.542 56.974 16.797 55.105 16.797V16.795ZM67.763 25.075C69.633 25.075 71.17 24.46 72.306 23.325L71.454 22.165C70.554 23.088 69.23 23.608 67.929 23.608C65.469 23.608 63.954 21.81 63.812 19.658H73.062V19.208C73.062 15.778 71.027 13.08 67.572 13.08C64.307 13.08 61.942 15.754 61.942 19.066C61.942 22.639 64.379 25.066 67.762 25.066L67.763 25.075ZM71.313 18.355H63.813C63.908 16.605 65.113 14.545 67.551 14.545C70.154 14.545 71.289 16.651 71.313 18.355ZM84.847 24.791V16.936C84.847 14.168 82.837 13.079 80.423 13.079C78.553 13.079 77.087 13.694 75.857 14.972L76.685 16.202C77.702 15.114 78.815 14.617 80.187 14.617C81.843 14.617 83.074 15.492 83.074 17.03V19.088C82.151 18.023 80.85 17.526 79.288 17.526C77.348 17.526 75.288 18.733 75.288 21.288C75.288 23.772 77.346 25.074 79.288 25.074C80.826 25.074 82.128 24.53 83.074 23.489V24.789L84.847 24.791ZM79.927 23.797C78.271 23.797 77.111 22.757 77.111 21.313C77.111 19.846 78.271 18.805 79.927 18.805C81.181 18.805 82.387 19.278 83.074 20.225V22.378C82.388 23.324 81.181 23.797 79.927 23.797ZM93.427 25.075C95.509 25.075 96.739 24.223 97.615 23.088L96.432 22C95.675 23.017 94.705 23.49 93.532 23.49C91.095 23.49 89.582 21.597 89.582 19.066C89.582 16.535 91.096 14.666 93.532 14.666C94.715 14.666 95.685 15.116 96.432 16.156L97.615 15.068C96.74 13.932 95.509 13.081 93.427 13.081C90.02 13.081 87.725 15.684 87.725 19.067C87.725 22.474 90.02 25.067 93.427 25.067V25.075ZM102.987 25.075C104.027 25.075 104.667 24.767 105.117 24.342L104.597 23.017C104.36 23.277 103.897 23.49 103.39 23.49C102.61 23.49 102.23 22.875 102.23 22.023V14.925H104.55V13.364H102.23V10.241H100.455V13.364H98.562V14.926H100.455V22.403C100.455 24.107 101.307 25.077 102.987 25.077V25.075Z"
            fill="white"
            fillOpacity="0.9"
        />
    </svg>
);

export default IconReact;
