import React from 'react';

type H3Props = {
    children?: React.ReactNode;
};

const H3: React.FC<H3Props> = ({ children }) => (
    <h3 id={`${children}`}>{children}</h3>
);
export default H3;
