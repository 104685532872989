import * as yup from 'yup';

const formSchema = yup.object().shape({
    email: yup
        .string()
        .required()
        .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),

    name: yup.string().min(3).required(),
    message: yup.string().min(3).required(),
});

export default formSchema;
