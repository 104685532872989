import React, { useEffect } from 'react';

import { MDXProvider } from '@mdx-js/react';
import { ThemeProvider } from 'styled-components';

import { Contact, Footer, Nav, Head } from '@components';
import {
    useProjectsShortQuery,
    useFooterQuery,
    useExternalLinksQuery,
} from '@hooks/queries';
import { theme, GlobalStyle } from '@styles';
import { handleExternalLinks } from '@utils';

interface PageProps {
    children: React.ReactNode;
}

const PageLayout: React.FC<PageProps> = ({ children }) => {
    const projects = useProjectsShortQuery();
    const links = useExternalLinksQuery();
    const footerData = useFooterQuery();

    useEffect(() => {
        handleExternalLinks();
    }, []);

    return (
        <>
            <Head />
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Nav content={{ projects, links }} />
                {children}
                <Contact />
                <Footer content={footerData} />
            </ThemeProvider>
        </>
    );
};

export default PageLayout;
