import React from 'react';

import styled from 'styled-components';

import { device } from '@styles';
import { DropdownProps } from 'src/types';

import ListItem from './ListItem';

const Wrapper = styled.div`
    position: absolute;
    top: 64px;
    left: -55px;
    width: 300px;
    padding: 24px 40px 26px 40px;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    &:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: -5px;
        left: 100px;
        z-index: -1;
        border-radius: 3px;
        background-color: var(--bg-gray);
        transform: rotate(45deg);
    }

    @media ${device.tablet} {
        display: block;
        top: 0;
        left: 20px;
        padding: 0;
        position: relative;
        height: 0px;
        width: 100%;
        overflow: hidden;
        transition: 0.5s;
        text-align: left;

        :before {
            display: none;
        }
    }
`;

const Background = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 8px;
    background-color: var(--bg-gray);
    z-index: -1;
    box-shadow: 0px 6px 10px hsl(0, 0%, 5%);

    @media ${device.tablet} {
        display: none;
    }
`;

const Dropdown: React.FC<DropdownProps> = ({ content }) => (
    <Wrapper>
        <Background />
        <ul>
            {content.map((item, i) => (
                <ListItem key={i} {...{ ...item }} />
            ))}
        </ul>
    </Wrapper>
);

export default Dropdown;
