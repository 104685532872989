import React from 'react';

const Minus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="white"
        stroke="currentColor"
    >
        <path d="M19 13H5v-2h14v2z"></path>
    </svg>
);

export default Minus;
