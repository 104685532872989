import React, { useMemo } from 'react';

import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import type { ParagraphProps } from 'src/types';

const Header = styled.h1``;

const GLink = styled(Link)`
    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 4;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
    }
`;

const Wrapper = styled.div<{
    size?: 'XL' | 'large';
    w?: 'restrained';
}>`
    & > *:not(:last-child) {
        margin-bottom: 20px;
    }

    ${props =>
        props.w?.includes('restrained')
            ? css`
                  max-width: 425px;
              `
            : css`
                  max-width: 600px;
              `}
`;

const Overline = styled.h5<{ color?: 'blue' | 'orange' | 'green' }>`
    font-size: 14px;
    color: ${({ color }) => (color ? `var(--${color})` : 'var(--p-color)')};
    ${({ color }) => (color === undefined ? '' : 'font-weight: 800;')}
`;

const Paragraph: React.FC<ParagraphProps> = ({ styles, data }) => {
    const { header, text, href, overline } = data;

    const tag = useMemo(() => {
        switch (styles?.size) {
            case 'XL':
                return 'h1';
            case 'large':
                return 'h2';
            default:
                return 'h3';
        }
    }, [styles?.size]);

    return (
        <Wrapper size={styles?.size} w={styles?.width}>
            {overline && <Overline color={styles?.color}>{overline}</Overline>}
            <Header as={tag}>
                {href ? (
                    <GLink to={href.replace(/\s/g, '')}>{header}</GLink>
                ) : (
                    header
                )}
            </Header>
            <p>{text}</p>
        </Wrapper>
    );
};

export default Paragraph;
