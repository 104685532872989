import React from 'react';

import { NavDataProvider } from '@hooks/useNav';
import { Nav as Layout } from '@layouts';
import type { NavbarProps } from 'src/types';

import Logo from './Logo';
import Navigation from './Navigation';
import SideBar from './SideBar';

const Nav: React.FC<NavbarProps> = ({ content }) => (
    <NavDataProvider content={content}>
        <Layout>
            <Logo />
            <Navigation />
            <SideBar />
        </Layout>
    </NavDataProvider>
);

export default Nav;
