import React, { useEffect, useRef } from 'react';

import styled, { css } from 'styled-components';

import { device } from '@styles';
import { sectionReveal } from '@utils';
import type { SectionProps } from 'src/types';

const Wrapper = styled.section<{ bgcolor: string | undefined }>`
    ${props =>
        !props.bgcolor
            ? css`
                  background-color: var(--black);
                  background-size: 1.2rem 1.2rem;
                  background-image: radial-gradient(
                      hsla(0, 0%, 100%, 0.1) 1px,
                      transparent 0
                  );
                  background-position: 0 -1.2rem;
              `
            : css`
                  background-color: ${props.bgcolor};
              `}

    @media ${device.laptop} {
        margin: 0 auto 0;
    }
`;

const Mask = styled.div<{ bgcolor: string | undefined }>`
    height: 100%;

    ${props =>
        !props.bgcolor
            ? css`
                  background: linear-gradient(
                          210deg,
                          transparent 10%,
                          var(--black) 60%,
                          transparent 90%
                      )
                      0 repeat;
              `
            : css`
                  background-color: ${props.bgcolor};
              `}
`;

const Content = styled.div<{ w: number }>`
    margin: 0 auto;
    padding: 100px 0;
    max-width: ${({ w }) => `${w}px`};
    position: relative;

    @media (max-width: 1330px) {
        padding: 100px 50px;
    }

    @media ${device.laptop} {
        padding: 75px 50px;
    }

    @media ${device.tablet} {
        padding: 0 50px;
    }

    @media ${device.mobileL} {
        padding: 0 25px;
    }
`;

const Section: React.FC<SectionProps> = ({
    children,
    bgcolor,
    w = 1280,
    id,
    refs,
    as,
    animate = true,
}) => {
    const refTarget = useRef(null);

    useEffect(() => {
        if (refs) refs.current.forEach(ref => sectionReveal(ref));
        sectionReveal(refTarget.current);
    }, [refs]);

    return (
        <Wrapper as={as} id={id} bgcolor={bgcolor}>
            <Mask bgcolor={bgcolor}>
                <Content w={w} ref={animate ? refTarget : null}>
                    {children}
                </Content>
            </Mask>
        </Wrapper>
    );
};

export default Section;
