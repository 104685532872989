import React, { AnchorHTMLAttributes } from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';

import Icon from '@components/Icon';
import { useNav } from '@hooks';
import { device } from '@styles';

import Dropdown from './Dropdown';

const Wrapper = styled.li<{ active: boolean }>`
    position: relative;

    &:hover,
    &:focus,
    &:active {
        & > div:last-of-type {
            visibility: visible;
            opacity: 1;

            @media ${device.tablet} {
                height: 210px;
                padding: 10px 0px;
            }
        }
    }

    @media ${device.tablet} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        &:hover {
            background-color: var(--bg-gray);
            border-radius: 0;
        }
    }
`;

const GLink = styled(Link)`
    position: relative;
    display: inline-block;
    padding: 15px 20px 15px 20px;

    @media ${device.tablet} {
        text-align: center;
        width: 100%;

        &:hover {
            background-color: var(--bg-gray);
            border-radius: 0;
        }
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0px;
        left: 0;
        background-color: var(--orange);
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
    }

    ${Wrapper}:hover &, ${Wrapper}:hover &::before {
        visibility: visible;
        transform: scaleX(1);
    }

    svg {
        margin-left: 5px;
        transition: all 0.3s ease-in-out;
    }

    ${Wrapper}:hover & > svg {
        transform: rotate(3.142rad);
    }

    @media ${device.tablet} {
        ${Wrapper}:hover &, ${Wrapper}:hover &::before {
            color: var(--white);
            visibility: visible;
            transform: scaleX(1);
        }
    }
`;

const Text = styled.h5`
    position: relative;
    text-decoration: none;
    opacity: 0.8;

    ${Wrapper}:hover & {
        opacity: 1;
    }
`;

interface NavItemProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    index: number;
}

const NavItem: React.FC<NavItemProps> = ({ index }) => {
    const { state, dispatch } = useNav();
    const { isOpen, navigation } = state;
    const { href, name, content } = navigation[index];

    return (
        <Wrapper active={isOpen}>
            <GLink
                to={href}
                onClick={() => dispatch({ type: 'close' })}
                arial-label={`Go to ${name}`}
            >
                <Container>
                    <Text>{name}</Text>
                    {content ? <Icon name="arrow" /> : ''}
                </Container>
            </GLink>
            {content ? <Dropdown content={content} /> : null}
        </Wrapper>
    );
};

export default NavItem;
