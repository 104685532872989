import React from 'react';

import {
    IconArrow,
    IconExternal,
    IconGame,
    Map,
    IconGitHub,
    IconMail,
    IconMigration,
    IconLinkedin,
    Logo,
    IconCss,
    IconHtml,
    IconReact,
    IconTypescript,
    IconStyledComponents,
    IconPhaser,
    IconPortfolio,
    IconHook,
    IconObserver,
    IconRespons,
    IconWebapp,
    IconDeploy,
    IconNew,
    IconBlog,
    IconToc,
    IconForm,
    Figma,
    Gatsby,
    Underline,
    LearnMore,
    Plus,
    Minus,
    CubeProjects,
    CubeOthers,
    CubeContact,
    Javascript,
    Nodejs,
    Express,
    Googlesheets,
    Next,
    Apollo,
    GraphQL,
    Redis,
    Tailwind,
    PostgreSQL,
    Mongodb,
    ReactHookForm,
    Test,
} from './icons';

const Icon = ({ name }: { name: string }) => {
    switch (name) {
        case 'apollo':
            return <Apollo />;
        case 'cubeContact':
            return <CubeContact />;
        case 'cubeOthers':
            return <CubeOthers />;
        case 'cubeProjects':
            return <CubeProjects />;
        case 'express':
            return <Express />;
        case 'blog':
            return <IconBlog />;
        case 'mail':
            return <IconMail />;
        case 'mongodb':
            return <Mongodb />;
        case 'migration':
            return <IconMigration />;
        case 'new':
            return <IconNew />;
        case 'external':
            return <IconExternal />;
        case 'figma':
            return <Figma />;
        case 'form':
            return <IconForm />;
        case 'deploy':
            return <IconDeploy />;
        case 'github':
            return <IconGitHub />;
        case 'game':
            return <IconGame />;
        case 'gatsby':
            return <Gatsby />;
        case 'graphql':
            return <GraphQL />;
        case 'googlesheets':
            return <Googlesheets />;
        case 'linkedin':
            return <IconLinkedin />;
        case 'logo':
            return <Logo />;
        case 'arrow':
            return <IconArrow />;
        case 'typescript':
            return <IconTypescript />;
        case 'toc':
            return <IconToc />;
        case 'html':
            return <IconHtml />;
        case 'css':
            return <IconCss />;
        case 'react':
            return <IconReact />;
        case 'map':
            return <Map />;
        case 'next':
            return <Next />;
        case 'node':
            return <Nodejs />;
        case 'phaser':
            return <IconPhaser />;
        case 'plus':
            return <Plus />;
        case 'postgresql':
            return <PostgreSQL />;
        case 'rhf':
            return <ReactHookForm />;
        case 'redis':
            return <Redis />;
        case 'portfolio':
            return <IconPortfolio />;
        case 'hook':
            return <IconHook />;
        case 'styled-components':
            return <IconStyledComponents />;
        case 'tailwind':
            return <Tailwind />;
        case 'observer':
            return <IconObserver />;
        case 'respons':
            return <IconRespons />;
        case 'webapp':
            return <IconWebapp />;
        case 'javascript':
            return <Javascript />;
        case 'more':
            return <LearnMore />;
        case 'minus':
            return <Minus />;
        case 'test':
            return <Test />;
        case 'underline':
            return <Underline />;
        default:
            return null;
    }
};

export default Icon;
