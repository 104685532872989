import React from 'react';

const CubeProjects = () => (
    <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M24.4955 27.4196V19.1722L20.1155 17.1955L16.4398 15.5403L16.4398 19.6082V23.6978L21.4133 25.9555L24.4955 27.4196Z"
            fill="#FFC400"
            fillOpacity="0.8"
        />
        <path
            d="M16.4398 23.6978L12.1667 25.9016L8.16652 28.0737L12.1667 30.0233L16.2222 32L20.0023 30.0233L22.0622 28.8755L24.4955 27.4196L21.4133 25.9555L16.4398 23.6978Z"
            fill="#FF9100"
            fillOpacity="0.8"
        />
        <path
            d="M24.4955 27.4196V19.1722L32.4444 15.5733V19.663V23.7526L24.4955 27.4196Z"
            fill="#9E9E9E"
            fillOpacity="0.8"
        />
        <path
            d="M8.27333 11.4019L1.90735e-06 15.846L4.05555 17.8226L8.16652 19.8263L12.3289 17.6727L16.4398 15.5403L12.3289 13.4126L8.27333 11.4019Z"
            fill="#616161"
            fillOpacity="0.8"
        />
        <path
            d="M8.16652 28.0737V19.8263L16.4398 15.5403L16.4398 19.6082V23.6978L8.16652 28.0737Z"
            fill="#9E9E9E"
            fillOpacity="0.8"
        />
        <path
            d="M8.16652 28.0737V19.8263L4.05555 17.8226L1.90735e-06 15.846V19.9356V24.0252L4.05555 26.036L8.16652 28.0737Z"
            fill="#FFC400"
            fillOpacity="0.8"
        />
        <path
            d="M24.4955 11.68L16.4398 15.5403L20.1155 17.1955L24.4955 19.1722L28.409 17.4004L32.4444 15.5733L27.9022 13.3022L24.4955 11.68Z"
            fill="#616161"
            fillOpacity="0.8"
        />
        <path
            d="M16.4398 15.5403V7.3438L11.949 5.31628L8.27333 3.66108L8.27333 7.72891L8.27333 11.4019L12.3289 13.4126L16.4398 15.5403Z"
            fill="#FFC400"
            fillOpacity="0.8"
        />
        <path
            d="M16.4398 15.5403V7.3438L24.4955 3.66108V7.72891V11.68L16.4398 15.5403Z"
            fill="#FF9100"
            fillOpacity="0.8"
        />
        <path
            d="M16.4398 0L8.27333 3.66108L11.949 5.31628L16.4398 7.3438L20.44 5.51555L24.4955 3.66108L19.7357 1.42295L16.4398 0Z"
            fill="#9E9E9E"
            fillOpacity="0.8"
        />
    </svg>
);

export default CubeProjects;
