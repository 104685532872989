import React from 'react';

import { Link } from 'gatsby';

import { useNav } from '@hooks';

import Icon from '../Icon';

const Logo: React.FC = () => {
    const { dispatch } = useNav();
    return (
        <Link
            aria-label="Move to home page"
            to="/"
            onClick={() => dispatch({ type: 'close' })}
        >
            <Icon name="logo" />
        </Link>
    );
};
export default Logo;
