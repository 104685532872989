import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledPage = styled.li`
    position: absolute;
    transition: transform 0.3s;

    &:nth-of-type(1) {
        transform: rotateY(40deg);
    }
    &:nth-of-type(2) {
        transform: rotateY(30deg);
    }
    &:nth-of-type(3) {
        transform: rotateY(20deg);
    }
`;

const Page: React.FC = () => (
    <StyledPage>
        <StaticImage
            className="book-img"
            placeholder="none"
            src="../../images/Untitled.jpg"
            quality={90}
            formats={['auto', 'webp', 'avif']}
            alt="page"
        />
    </StyledPage>
);

export default Page;
