import React from 'react';

const IconNew = () => (
    <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.38876 17.8794C7.26667 17.7595 7.14367 17.6397 7.01979 17.5207C5.75681 16.31 4.50366 15.098 3.32788 13.7997C3.04299 13.4853 2.5081 12.8753 1.97098 12.5613C1.5868 12.3368 1.1892 12.2429 0.825599 12.2894C0.593932 12.319 0.255391 12.4357 0.0854423 12.8798C0.0568194 12.9545 0.0228374 13.0828 0.00942039 13.2465C-0.0120468 13.5113 0.00896289 13.9862 0.0156714 14.052C0.10959 15.0099 0.203506 15.9259 0.375691 16.8736C0.534011 17.7457 0.642237 18.6245 0.812633 19.4953C1.06756 20.798 1.39583 22.0521 1.87437 23.2927C2.05193 23.7533 2.57027 23.9828 3.03092 23.8052C3.49112 23.6277 3.721 23.1098 3.54345 22.6491C3.10292 21.5065 2.80283 20.3517 2.56848 19.1518C2.39943 18.2891 2.29253 17.4178 2.13555 16.5538C2.03358 15.9916 1.96112 15.4411 1.89941 14.8856L2.00182 15.0005C3.20622 16.3302 4.48845 17.5721 5.78185 18.8119C6.26575 19.2761 6.73312 19.7538 7.22463 20.2104C7.26443 20.2685 7.44823 20.5351 7.53857 20.6263C7.68884 20.7784 7.85119 20.8633 7.99252 20.9054C8.35164 21.0127 8.71615 20.9729 9.03458 20.5704C9.27295 20.269 9.41023 19.8016 9.39995 19.2434C9.38608 18.4764 9.09496 17.4653 9.03324 16.9993C8.64236 14.0506 8.00997 11.1137 7.84181 8.13957C7.81408 7.64672 7.39144 7.26924 6.89859 7.29697C6.40574 7.32514 6.02782 7.74777 6.05599 8.24062C6.22639 11.2594 6.86282 14.2412 7.25951 17.2345C7.28098 17.3937 7.33196 17.6204 7.38876 17.8794Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2044 5.6567C14.19 5.92549 13.1739 6.15983 12.3591 6.85662C11.5066 7.58605 11.0733 8.64556 10.919 9.82625C10.7222 11.3325 10.9861 13.0414 11.1498 14.2592C11.2347 14.8898 11.271 15.7686 11.4548 16.5473C11.6131 17.2168 11.8828 17.8165 12.2938 18.2365C12.9396 18.8961 13.9306 18.9628 14.9472 18.5513C16.1216 18.0759 17.2912 16.9788 17.6091 16.3393C17.8292 15.8974 17.6489 15.3599 17.2066 15.1403C16.7647 14.9202 16.2276 15.1005 16.0076 15.5423C15.8059 15.948 15.021 16.5915 14.2759 16.8934C14.1095 16.9605 13.9449 17.0106 13.7893 17.0231C13.7084 17.0298 13.6274 17.0415 13.5724 16.9856C13.3027 16.7096 13.2079 16.261 13.1297 15.808C13.0201 15.1765 12.9897 14.5168 12.923 14.0204C12.7763 12.9314 12.5169 11.405 12.6927 10.0579C12.7871 9.33564 13.0004 8.66256 13.5223 8.21622C14.1279 7.69833 14.9078 7.58606 15.6623 7.38615C16.1395 7.26003 16.4244 6.76986 16.2978 6.29266C16.1717 5.81547 15.6816 5.53058 15.2044 5.6567Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1358 10.37C14.35 10.798 13.5101 11.238 12.668 11.5403C12.2033 11.7072 11.9614 12.2197 12.1277 12.6844C12.2945 13.1491 12.8071 13.391 13.2717 13.2242C14.1993 12.8914 15.1264 12.4125 15.9914 11.9415C16.4247 11.7054 16.5853 11.1616 16.3491 10.7282C16.113 10.2944 15.5696 10.1343 15.1358 10.37Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.9384 10.8448C25.2855 10.055 24.5954 9.2777 23.8664 8.56749C23.8069 8.5098 23.0717 7.90424 22.6347 7.67794C22.34 7.52543 22.0788 7.49771 21.9258 7.51246C21.4808 7.5554 21.2094 7.79646 21.098 8.20881C20.9916 8.60416 20.9531 9.01606 20.9531 9.43646C20.2917 8.19628 19.6866 6.92078 19.3189 5.57774C19.2885 5.46683 19.2639 5.41494 19.2599 5.40555C19.136 5.13184 18.9602 5.0388 18.8976 5.00213C18.5899 4.82145 18.3127 4.85188 18.0788 4.95698C18.0501 4.9695 17.4195 5.24947 17.5702 5.96459C17.5823 6.02228 17.6852 6.43328 17.7152 6.59384C17.7415 6.73338 17.7988 6.85905 17.8788 6.96549C18.4951 8.73564 19.4379 10.4078 20.3426 12.0407C20.5381 12.3936 20.6906 12.7572 20.7912 13.1476C20.8207 13.2616 20.8328 13.5197 20.8588 13.6462C20.903 13.8605 20.9907 14.004 21.0466 14.0774C21.3373 14.4593 21.6955 14.4982 22.026 14.4003C22.1673 14.3587 22.4504 14.2634 22.6226 13.8533C23.0099 12.932 22.9661 11.9146 22.8539 10.8895C22.8208 10.5863 22.7796 10.2831 22.7546 9.98341C23.5502 10.7741 24.2948 11.6462 24.9948 12.5179C25.0717 12.6136 25.8508 13.4848 26.0578 13.6145C26.5757 13.9401 26.9778 13.6954 27.15 13.5496C27.4125 13.3269 27.6191 12.9446 27.7032 12.4575C27.8114 11.8274 27.726 10.9602 27.7175 10.6113C27.6607 8.26335 27.5757 5.80895 27.0484 3.51644C26.938 3.03522 26.4577 2.73466 25.9764 2.84513C25.4952 2.95559 25.1947 3.43638 25.3051 3.91716C25.8056 6.09429 25.8749 8.42571 25.929 10.6547C25.9304 10.7062 25.9339 10.7706 25.9384 10.8448Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.5126 2.92166C29.8369 4.48921 30.1616 6.04244 30.3735 7.65426C30.4379 8.14398 30.8874 8.48926 31.3771 8.42486C31.8664 8.36045 32.2117 7.91098 32.1473 7.42126C31.8485 5.14797 31.3315 2.98964 30.8986 0.747212C30.7318 -0.119525 29.908 0.00168739 29.8574 0.010632C29.7461 0.0303103 29.1392 0.159559 29.1191 0.870212C29.1177 0.913594 29.1468 1.22037 29.1517 1.33934C29.1624 1.6117 29.1571 1.87782 29.1432 2.15063C29.1271 2.46503 29.2751 2.74992 29.5126 2.92166Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.8275 12.1654C31.9183 11.889 31.8722 11.5733 31.6772 11.3286C31.3691 10.9427 30.806 10.8796 30.42 11.1878C30.0636 11.4722 29.997 11.8188 30.0448 12.2428C30.1003 12.7334 30.5439 13.0867 31.0346 13.0308C31.4831 12.9803 31.8168 12.6046 31.8275 12.1654Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9422 29.4197C14.8509 29.2265 14.7776 29.074 14.7601 29.0414C13.9292 27.4872 13.0275 25.9537 12.0459 24.4899C11.7708 24.0798 11.2149 23.9702 10.8048 24.2452C10.3947 24.5203 10.2851 25.0762 10.5602 25.4863C11.5088 26.9005 12.3795 28.3831 13.1827 29.8849C13.244 29.9994 13.6523 31.0168 13.9439 31.4412C14.2082 31.8254 14.5401 31.9627 14.7302 31.9909C15.032 32.0356 15.5043 31.9274 15.8876 31.4157C16.3881 30.748 17.037 28.964 17.1439 28.6679C17.3398 28.1232 17.5057 27.5516 17.6595 26.9957C17.9511 25.9412 18.1188 24.8678 18.2687 23.7864C18.3366 23.2971 17.9945 22.8454 17.5053 22.7774C17.0164 22.7099 16.5643 23.052 16.4967 23.5409C16.3577 24.544 16.206 25.5404 15.9355 26.5185C15.7932 27.0315 15.6412 27.5597 15.4605 28.0624C15.4059 28.2131 15.1939 28.8356 14.9422 29.4197Z"
            fill="white"
        />
    </svg>
);

export default IconNew;
