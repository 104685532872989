import React from 'react';

import styled from 'styled-components';

import { SideBar } from '@components';
import { useTagsQuery } from '@hooks/queries';
import { device } from '@styles';
import type { TagContent } from 'src/types';

import Page from '../layouts/Page';
import Section from '../layouts/Section';

const Container = styled.div`
    display: grid;
    grid-template-columns: minmax(125px, 370px) minmax(540px, 800px);

    @media ${device.tablet} {
        display: block;
    }
`;

type BlogProps = {
    children: React.ReactNode;
    withContent?: boolean;
    sections?: HTMLElement[] | undefined;
    title?: string;
};

const Blog: React.FC<BlogProps> = ({
    children,
    withContent = false,
    sections,
    title,
}) => {
    const group: TagContent[] = useTagsQuery();
    return (
        <Page>
            <Section animate={false}>
                <Container>
                    <SideBar
                        tags={group}
                        sections={sections}
                        withContent={withContent}
                        title={title}
                    />
                    <div>{children}</div>
                </Container>
            </Section>
        </Page>
    );
};

export default Blog;
