import React from 'react';

import styled from 'styled-components';

import Links from '@components/Links';
import { useNav } from '@hooks';
import { device } from '@styles';

import Hamburger from './Hamburger';

const Wrapper = styled.div`
    & > div:first-of-type {
        @media ${device.tablet} {
            display: none;
        }
    }
`;

const SideBar: React.FC = () => {
    const { state } = useNav();
    const { links } = state;

    return (
        <Wrapper>
            <Links content={links} />
            <Hamburger />
        </Wrapper>
    );
};

export default SideBar;
