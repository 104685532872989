import { graphql, useStaticQuery } from 'gatsby';

import type { TagContent } from 'src/types';

const useTagsQuery = () => {
    const { tags } = useStaticQuery(graphql`
        {
            tags: allMdx {
                group(field: frontmatter___tags) {
                    value: fieldValue
                    count: totalCount
                }
            }
        }
    `);
    const content: TagContent[] = tags.group;
    return content;
};

export default useTagsQuery;
