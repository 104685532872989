import React from 'react';

import styled from 'styled-components';

import { device } from '@styles';

import ListItem from './ListItem';

const Wrapper = styled.div`
    grid-column: 5;
    grid-row: 1 / 3;
    margin-bottom: 64px;

    @media ${device.tablet} {
        grid-column: 1 / 2;
        grid-row: 4 / 5;
    }
`;

const Container = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-column: 2 / 5;
    margin-bottom: 64px;
`;

const General: React.FC = () => (
    <Wrapper>
        <h4>GENERAL</h4>
        <Container>
            <ListItem section="about" />
            <ListItem href="/blog" internal>
                Blog
            </ListItem>
            <ListItem section="other">Other projects</ListItem>
            <ListItem href="/resume.pdf">Resume</ListItem>
        </Container>
    </Wrapper>
);

export default General;
