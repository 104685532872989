import React from 'react';

const IconToc = () => (
    <svg
        width="72"
        height="40"
        viewBox="0 0 72 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 8H56V0H0V8ZM0 24H56V16H0V24ZM0 40H56V32H0V40ZM64 40H72V32H64V40ZM64 0V8H72V0H64ZM64 24H72V16H64V24Z"
            fill="white"
        />
    </svg>
);

export default IconToc;
