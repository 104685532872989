import ScrollReveal from 'scrollreveal';

import srConfig from './srConfig';

const isBrowsing = typeof window === 'undefined';
const sr = isBrowsing ? null : ScrollReveal();

const sectionReveal = (target: HTMLElement | null, delay?: number) => {
    if (target === null || undefined) return;
    sr?.reveal(target, srConfig(delay));
};

export default sectionReveal;
