import React from 'react';

import styled from 'styled-components';

import { useSlider } from '@components/Slider';

import Button from '../Button';
import Paragraph from '../Paragraph';

const StyledFront = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
`;

const Front: React.FC = () => {
    const { nextStep } = useSlider();

    return (
        <StyledFront>
            <Paragraph
                data={{
                    header: 'Contact',
                    text: 'Want to reach out about a project, collaboration, or just want to say friendly hello?',
                    overline: 'GET IN TOUCH',
                }}
                styles={{
                    color: 'orange',
                    width: 'restrained',
                }}
            />
            <Button square arrow onClick={nextStep}>
                Contact form
            </Button>
        </StyledFront>
    );
};

export default Front;
