/* eslint-disable @typescript-eslint/no-namespace */
import React from 'react';

import styled, { css } from 'styled-components';

import Icon from '@components/Icon';
import { device } from '@styles';

const IconWrapper = styled.div<{ pure: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    border: initial;
    font-weight: 900;

    ${props =>
        !props.pure &&
        css`
            svg {
                height: 13px;
                width: 13px;
            }
        `}
`;

const Arrow = styled.span`
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateX(0);
    margin-left: 5px;
`;

const StyledButton = styled.button<{
    primary: boolean;
    pure?: boolean;
    arrow?: boolean;
    icon?: boolean;
    square: boolean;
    disabled?: boolean;
}>`
    display: flex;
    position: relative;
    font-size: 16px;
    width: fit-content;
    border: none;
    border-radius: ${({ square }) => (square ? '10px' : '100px')};
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    z-index: 50;
    font-family: inherit;

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;

        > :nth-child(2) {
            margin-left: 5px;
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    span {
        display: inline-block;
    }

    ${props =>
        !props.pure &&
        css`
            padding: 12px 20px;

            @media ${device.tablet} {
                padding: 12px 15px;
            }
        `}

    ${props =>
        props.primary &&
        css`
            margin-right: 20px;
            background-color: var(--orange);
            color: var(--white);
            border-color: var(--orange);
            transition: background-color 0.15s;
            order: 0;

            &:hover {
                background-color: var(--light-orange);
            }
        `};

    ${props =>
        !props.primary &&
        !props.pure &&
        css`
            order: 1;
            background: transparent;
            color: var(--white);
            border-color: var(--white);
            box-shadow: inset 0px 0px 0px 1px #566171;
            transition: color 150ms, border-color 150ms, box-shadow 150ms,
                background-color 0.4s;

            &:hover {
                color: ${props.color};
                box-shadow: inset 0px 0px 0px 1px ${props.color};
            }
        `};

    ${props =>
        props.arrow &&
        css`
            &:hover {
                ${Arrow} {
                    transform: translateX(45%);
                }
            }
        `}

    ${props =>
        props.disabled &&
        css`
            opacity: 0.4;
            cursor: default;
        `}

    @media ${device.tablet} {
        font-size: 13px;
        line-height: 13px;
    }
`;

interface ButtonProps {
    children?: React.ReactNode;
    primary?: boolean;
    square?: boolean;
    arrow?: boolean;
    icon?: string;
    href?: string;
    pure?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
    onClick?: (event: React.MouseEvent<HTMLElement | null>) => void;
}

const Button: React.FC<ButtonProps> = ({
    children,
    primary = false,
    arrow = false,
    square = false,
    pure = false,
    icon,
    href,
    type,
    onClick,
}) => (
    <>
        {href ? (
            <StyledButton
                icon={!!icon}
                primary={primary}
                arrow={arrow}
                square={square}
                as="a"
                href={href}
                pure={pure}
                onClick={onClick}
                aria-label={`${pure && 'website-logo'}`}
            >
                <div>
                    <div>{children && children}</div>
                    {icon && (
                        <IconWrapper pure={pure}>
                            <Icon name={icon} />
                        </IconWrapper>
                    )}
                    {arrow && <Arrow>&nbsp;&#10132;</Arrow>}
                </div>
            </StyledButton>
        ) : (
            <StyledButton
                icon={!!icon}
                primary={primary}
                square
                onClick={onClick}
                type={type}
                arrow={arrow}
            >
                <div>
                    {icon && (
                        <IconWrapper pure={pure}>
                            <Icon name={icon} />
                        </IconWrapper>
                    )}
                    <div>{children && children}</div>
                    {arrow && <Arrow>&nbsp;&#10132;</Arrow>}
                </div>
            </StyledButton>
        )}
    </>
);

export default Button;
