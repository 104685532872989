import React, { useEffect, useState } from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { device } from '@styles';

import Page from './Page';

const Wrapper = styled.ul<{ delay: string; active: boolean }>`
    display: block;
    width: 170px;
    height: 250px;
    position: relative;
    perspective: 700px;

    opacity: ${({ active }) => (active ? '1' : '0')};
    transform: ${({ active }) =>
        active ? 'translateY(0px)' : 'translateY(20px)'};
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1)
            ${({ delay }) => delay},
        transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1)
            ${({ delay }) => delay};

    &:hover li {
        &:nth-of-type(1) {
            transform: rotateY(-15deg);
        }
        &:nth-of-type(2) {
            transform: rotateY(-27deg);
        }
        &:nth-of-type(3) {
            transform: rotateY(-34deg);
        }
    }

    li {
        transform-origin: 0% 50% 0;
        transform-style: preserve-3d;

        .book-img {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }

    @media ${device.tablet} {
        height: 200px;
        width: 136px;
    }
`;

const Cover = styled.li`
    position: relative;
    transition: transform 0.3s;

    ${Wrapper}:hover & {
        transform: rotateY(-40deg);
    }
`;

const Text = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px 15px;

    h4 {
        font-size: 16px;
        max-width: 70%;
        line-height: 1.2;

        @media ${device.tablet} {
            font-size: 14px;
        }
    }

    p {
        font-size: 14px;
        padding: 15px 0px;
        line-height: 17px;

        @media ${device.tablet} {
            font-size: 10px;
            line-height: 1.2;
        }
    }
`;

const StyledDate = styled.span`
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px 15px;
    font-family: var(--font-mono);
    text-transform: uppercase;
    font-size: 12px;
`;

const Overlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    background: -webkit-linear-gradient(
        right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
    );
    transition: 0.3s ease;
    opacity: 0;

    ${Wrapper}:hover & {
        opacity: 1;
    }
`;

interface BookProps
    extends React.HTMLAttributes<HTMLUListElement>,
        React.ClassAttributes<HTMLLIElement> {
    content: {
        name: string;
        description: string;
        date: string;
        slug: string;
    };
    delay?: string;
}

const Book: React.FC<BookProps> = ({ content, delay = '' }) => {
    const [animate, setAnimate] = useState(false);
    const { name, description, date } = content;

    useEffect(() => {
        if (animate !== true) {
            setAnimate(true);
        }
    }, []);

    return (
        <Wrapper delay={delay} active={animate}>
            <Page />
            <Page />
            <Page />
            <Cover>
                <StaticImage
                    className="book-img"
                    src="../../images/Cover.png"
                    formats={['auto', 'webp', 'avif']}
                    alt="cover"
                />
                <Text>
                    <h4>{name}</h4>
                    <p>{description}</p>
                </Text>
                <StyledDate>{new Date(date).toLocaleDateString()}</StyledDate>
                <Overlay />
            </Cover>
        </Wrapper>
    );
};

export default Book;
