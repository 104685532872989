import React from 'react';

const Underline = () => (
    <svg
        width="360"
        height="15"
        viewBox="0 0 360 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152.896 1.42503C135.983 1.91338 119.269 2.51384 102.97 3.17031C79.8961 4.09897 56.8317 5.05965 33.9063 6.58073C26.6899 7.06107 19.3615 7.36527 12.1902 7.96569C7.68853 8.34196 1.7194 8.87826 0.936027 8.99034C0.526291 9.05439 0.347537 9.15055 0.289777 9.18257C-0.11635 9.40673 -0.0422469 9.62279 0.186989 9.79091C0.279044 9.86296 0.511825 10.0392 1.15802 10.0632C44.3735 11.7043 88.5637 8.50207 131.832 8.16584C206.866 7.58943 284.174 9.87905 358.613 14.8746C359.262 14.9146 359.894 14.7144 359.984 14.4182C360.093 14.13 359.624 13.8498 358.974 13.8098C284.409 8.80623 206.974 6.50859 131.795 7.09301C91.4716 7.40523 50.3517 10.2153 9.97186 9.25459C10.8996 9.17454 11.8094 9.09445 12.6397 9.0224C19.7821 8.42197 27.0798 8.12579 34.2673 7.64545C57.144 6.12437 80.1614 5.16369 103.204 4.24304C131.795 3.09023 161.632 2.09752 191.704 1.63319C202.461 1.70524 213.183 1.77732 223.905 1.86538C247.099 2.05752 270.402 2.61792 293.542 3.37045C300.51 3.60261 307.477 3.84277 314.444 4.05091C316.755 4.12296 322.711 4.33108 323.541 4.31506C324.57 4.29905 324.769 3.91482 324.787 3.85077C324.841 3.70667 324.805 3.50652 324.299 3.3384C324.245 3.31438 323.92 3.23427 323.198 3.18624C281.124 0.352234 236.197 -0.120026 191.74 0.560455C144.846 0.264246 97.7711 0.136096 50.9961 0C50.3265 0 49.7795 0.240205 49.7741 0.536415C49.7705 0.832624 50.3102 1.07284 50.9798 1.08084C84.849 1.17691 118.89 1.27292 152.896 1.42503Z"
            fill="white"
        />
    </svg>
);

export default Underline;
