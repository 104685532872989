import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { useSlider } from '@components/Slider';

const StyledCross = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? 'initial' : 'none')};

    circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 3;
        stroke-miterlimit: 10;
        stroke: #ff0000;
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    svg {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 3;
        stroke: #ff0000;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px #ff0000;
        animation: scale 0.3s ease-in-out 0.9s both;
    }

    path {
        stroke: #ff0000;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        transform-origin: 50% 50% 0;
    }

    .cross__path--right {
        animation: 0.3s ease 0.8s normal forwards 1 running stroke;
    }

    .cross__path--left {
        animation: 1s ease 0.8s normal forwards 1 running stroke;
    }

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }

    h3 {
        text-align: center;
        color: #ff0000;
    }
`;

const Cross: React.FC = () => {
    const { step, nextStep } = useSlider();
    const [active, setActive] = useState(false);
    useEffect(() => {
        if (step === 2) {
            setActive(true);
            setTimeout(() => {
                nextStep();
                setActive(false);
            }, 2500);
        }
    }, [step]);

    return (
        <StyledCross active={active}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle cx="26" cy="26" r="25" fill="none" />
                <path
                    className="cross__path cross__path--right"
                    fill="none"
                    d="M16,16 l20,20"
                />
                <path
                    className="cross__path cross__path--left"
                    fill="none"
                    d="M16,36 l20,-20"
                />
            </svg>

            <h3>Message not send</h3>
        </StyledCross>
    );
};

export default Cross;
