import { graphql, useStaticQuery } from 'gatsby';

import { ExternalLinksNode } from 'src/types';

const useExternalLinksQuery = () => {
    const { links } = useStaticQuery(graphql`
        {
            links: mdx(internal: { contentFilePath: { regex: "/links/" } }) {
                frontmatter {
                    linkIcons
                    links
                }
            }
        }
    `);
    const content: ExternalLinksNode = links.frontmatter;

    return content;
};

export default useExternalLinksQuery;
