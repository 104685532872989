import React from 'react';

import styled, { css } from 'styled-components';

import { useNav, useScrollUp } from '@hooks';
import { device } from '@styles';

const Wrapper = styled.nav<{ scrollUp: boolean }>`
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    height: var(--nav-height);
    background-color: var(--black);
    transition: all 0.2s ease-in-out;

    ${props =>
        props.scrollUp &&
        css`
            transform: translateY(calc(var(--nav-height) * -5));
        `};
    @media (max-width: 1080px) {
        padding: 0 50px;
    }
    @media ${device.tablet} {
        position: fixed;
        padding: 0 50px;
    }
    @media ${device.mobileL} {
        padding: 0 25px;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    text-align: left;
    height: 100%;

    a {
        z-index: 5;
    }
`;

type NavProps = {
    children: React.ReactNode;
};

const Nav: React.FC<NavProps> = ({ children }) => {
    const scrollUp = useScrollUp();
    const { state } = useNav();
    const { isOpen } = state;

    return (
        <Wrapper scrollUp={!scrollUp && !isOpen}>
            <Container>{children}</Container>
        </Wrapper>
    );
};

export default Nav;
