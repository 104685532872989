import React from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';

import Icon from '@components/Icon';
import { useNav } from '@hooks';

const Description = styled.div`
    font-size: var(--fs-xs);
    color: #8b96aa;
    transition: color 300ms;
`;

const Item = styled.li`
    line-height: 1.5rem;
    letter-spacing: 0.6px;
    font-size: var(--fs-sm);
    color: var(--white);
    font-weight: 500;
    transition: color 300ms;

    &:hover,
    &:hover ${Description} {
        color: var(--orange);
    }

    a {
        padding: 5px 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
            width: 20px;
            height: 20px;
        }
    }
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: 30px;
    width: 30px;
    margin-right: 8px;

    ${Item}:hover & {
        background: hsl(206, 14%, 17%);
    }
`;

export type ItemProps = {
    name: string;
    slug: string;
    icon: string;
    quickDesc?: string;
};

const ListItem: React.FC<ItemProps> = ({ name, slug, icon, quickDesc }) => {
    const { dispatch } = useNav();

    return (
        <Item>
            <Link
                to={`/projects/${slug}`}
                arial-label={`Go to ${slug} project`}
                onClick={() => dispatch({ type: 'close' })}
            >
                <IconWrapper>
                    <Icon name={icon} />
                </IconWrapper>
                <div>
                    <span>{name}</span>
                    {quickDesc && <Description>{quickDesc}</Description>}
                </div>
            </Link>
        </Item>
    );
};

export default ListItem;
