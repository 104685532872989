import { useEffect, useState } from 'react';

import type { ContentProject } from 'src/types';

const getSections = () => {
    // gets the list of nodes with tag section from the the document object.
    const sections = document.querySelectorAll('section');

    // return only sections with id, excluding 'contact' and 'hero' section.
    const list: HTMLElement[] = [];

    sections.forEach(section => {
        if (section.id === '') return;
        if (section.id === 'contact') return;
        if (section.id === 'hero') return;
        list.push(section);
    });

    return list;
};

const useCheckActiveSections = (content: ContentProject) => {
    const [sections, setSections] = useState<HTMLElement[]>([]);

    useEffect(() => {
        setSections(getSections());
    }, [content]);

    return sections;
};

export default useCheckActiveSections;
