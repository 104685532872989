import React from 'react';

import background from './background';
import { plArrow, plText, ukArrow, ukText, poland, uk } from './foreground';

const Map = () => (
    <svg
        width="494"
        height="502"
        viewBox="0 0 494 502"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="white"
        strokeWidth="1.5"
    >
        {background.map(country => (
            <path key={country} className="bg" d={country} />
        ))}
        <path className="poland" d={poland} fill="currentColor" />
        <path className="uk" d={uk} fill="currentColor" />
        <path className="uk-arrow" d={ukArrow} fill="white" />
        <path className="poland-arrow" d={plArrow} fill="white" />
        <path className="uk-text" d={ukText} stroke="none" fill="white" />
        <path className="poland-text" d={plText} stroke="none" fill="white" />
    </svg>
);

export default Map;
