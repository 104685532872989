import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div<{ r: number }>`
    width: ${({ r }) => `${r}px`};
    height: ${({ r }) => `${r}px`};
    z-index: 10;
    overflow: hidden;
`;

const StyledLoader = styled.div`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #16a085;
    border-right-color: #16a085;
    animation: spin 1.7s linear infinite;
    z-index: 11;

    &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: #e74c3c;
        border-right-color: #e74c3c;
        animation: spin-reverse 0.8s linear infinite;
    }

    &:after {
        content: '';
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: #f9c922;
        border-right-color: #f9c922;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes spin-reverse {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(-360deg);
        }
    }
`;

const Layer = styled.div`
    width: 51%;
    height: 100%;
    background: #222;
    z-index: 10;

    &:nth-of-type(1) {
        left: 0;
    }

    &:nth-of-type(2) {
        right: 0;
    }
`;

type LoaderProps = {
    r?: number;
};

const Loader: React.FC<LoaderProps> = ({ r = 30 }) => (
    <Wrapper r={r}>
        <StyledLoader />
        <Layer />
        <Layer />
    </Wrapper>
);

export default Loader;
