import React from 'react';

const IconHtml = () => (
    <svg
        width="125"
        height="43"
        viewBox="0 0 125 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M87 0H125L121.538 38.6952L105.978 43L90.4592 38.7012L87 0Z"
            fill="white"
            fillOpacity="0.65"
        />
        <path
            d="M118.332 36.4788L121 3H107V40L118.332 36.4788Z"
            fill="white"
            fillOpacity="0.4"
        />
        <path
            d="M100.656 17.2918L100.218 12.5903H107V8H95L96.2906 21.8833H107V17.293L100.656 17.2918ZM96.4912 24.1796L97.1619 31.3896L106.966 34L106.988 33.9942V29.2185L106.967 29.2243L101.65 27.8333L101.309 24.1703H98.7196L96.4912 24.1796Z"
            fill="#EBEBEB"
        />
        <path
            d="M117.09 18.5331L117.197 17.2999H107V21.8895H112.402L111.892 27.8434L107 29.2249V34L115.994 31.3922L116.061 30.6157L117.09 18.5331ZM107.01 12.6013H117.607L118 8H107.01V12.5781V12.6013Z"
            fill="white"
            fillOpacity="0.9"
        />
        <path
            d="M0 14H5.53936V18.9499H10.6054V14H16.1448V28.9903H10.6054V23.9789H5.53936V28.9903H0V14ZM23.4276 18.9719H18.5423V14.0009H33.8348V18.9719H28.9563V28.9921H23.4169V18.9719H23.4276ZM36.2624 14H42.0379L45.5909 19.2752L49.1399 14H54.9077V28.9903H49.3916V21.5593L45.5821 26.8899H45.4869L41.691 21.5602V28.9912H36.2721L36.2624 14ZM57.6715 14H63.2109V24.0448H71V29H57.6725L57.6715 14Z"
            fill="white"
            fillOpacity="0.9"
        />
    </svg>
);

export default IconHtml;
