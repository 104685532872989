import React from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';

import Icon from '@components/Icon';
import { Section } from '@layouts';
import { device } from '@styles';
import type { FooterProps } from 'src/types';

import Copyright from './Copyright';
import General from './General';
import Projects from './Projects';

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 100px;
    grid-template-rows: repeat(4, auto);
    column-gap: 24px;

    & > {
        :nth-child(1) {
            position: relative;
            top: -15px;
        }
    }

    h4 {
        line-height: 16px;
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0.1rem;
        margin-bottom: 16px;
    }

    @media ${device.tablet} {
        grid-template-columns: repeat(3, auto);
        & > {
            :nth-child(1) {
                grid-row: 1;
                margin-bottom: 64px;
                top: 0;
            }
        }
    }
`;

const Footer: React.FC<FooterProps> = ({ content, animate = true }) => (
    <Section as="footer" animate={animate}>
        <Grid>
            <Link to="/" aria-label="Move to home page">
                <Icon name="logo" />
            </Link>
            <Projects content={content} />
            <General />
            <Copyright />
        </Grid>
    </Section>
);

export default Footer;
