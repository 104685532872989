import { createGlobalStyle } from 'styled-components';

import device from './device';
import PrismStyles from './PrismStyles';
import TransitionStyle from './TransitionStyle';
import variables from './variables';

const GlobalStyle = createGlobalStyle`
    ${variables};

    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

    body {
        
        background-color: var(--black);
        text-align: left;
        font-family: var(--font-sans);
        color: var(--white);
        width: 100%;
        min-height: 100%;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
    }

    html {
        scroll-behavior: smooth; 
        scrollbar-width: thin;
        scrollbar-color: var(--black) var(--orange);
    }

    body::-webkit-scrollbar {
        width: 8px;
    }

    body::-webkit-scrollbar-track {
        background: var(--black);
    }

    body::-webkit-scrollbar-thumb {
        background-color: var(--black);
        border: 2px solid var(--orange);
        border-radius: 10px;
    }

    /* text globals */

    ul, li, nav {
        list-style-type: none;
    }

    h1 {
        font-size: clamp(2.625rem, 1.2rem + 3.5vw, 4rem);
        line-height: 1.1;
        font-weight: 800;
    }

    h2 {
        font-size: clamp(2rem, 0.9rem + 1.8vw, 3.5rem);
        line-height: 1.5;
        font-weight: 900;
    }

    h3 {
        font-size: 2rem;
        font-weight: 900;
        line-height: 2.5rem;

        @media ${device.tablet} {
            font-size: 22px;
            line-height: 2rem;
        }
    }


    h4 {
        font-size: 1rem;
        font-weight: 900;
        line-height: 1.5rem;
        color: var(--white);

        @media ${device.laptop} {
            font-size: 0.875rem;
            line-height: 1.35rem;
        }
    }

    h5 {
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.375rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: var(--font-sans);
        letter-spacing: initial;
    }

    p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.6rem;
        letter-spacing: 0.6px;
        color: var(--p-color);

        @media ${device.laptop} {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    /* link globals */

    a:active,
    a:link,
    a:visited {
        color: inherit;
        text-decoration: none;
    }

    img,
    svg {
        height: auto;
        max-width: 100%;
    }

    ${TransitionStyle}
    ${PrismStyles}
`;

export default GlobalStyle;
