import React, { useMemo } from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';

import { device } from '@styles';

const Wrapper = styled.li`
    position: relative;
    color: var(--p-color);

    a::before {
        content: '-';
        position: absolute;
        width: 1px;
        height: 100%;
        left: 2.5px;
        color: var(--bg-gray);
        background: var(--bg-gray);
    }

    a {
        display: block;
        padding: 5px 0 5px 15px;
        font-size: 14px;
        line-height: 20px;
        transition: color 0.15s;
        white-space: nowrap;
    }

    &:last-of-type > a::before {
        height: 10px;

        @media ${device.tablet} {
            height: 11px;
        }
    }

    :hover {
        a {
            color: var(--white);
        }
    }
`;

export type ListItemProps = {
    children?: React.ReactNode;
    href?: string;
    internal?: boolean;
    name?: string;
    section?: string;
};

const ListItem: React.FC<ListItemProps> = ({
    internal,
    children,
    href,
    name,
    section,
}) => {
    const sectionTitle = useMemo(() => {
        if (section) {
            const result =
                section.charAt(0).toLocaleUpperCase() + section.slice(1);
            return result;
        }
        return undefined;
    }, []);
    const dest = href?.replace('/', '');

    return (
        <Wrapper>
            {name && (
                <Link
                    to={`/projects/${name}/#${sectionTitle}`}
                    aria-label={`Go to ${sectionTitle} section of the ${name} project`}
                >
                    {sectionTitle}
                </Link>
            )}
            {!name && !href && (
                <Link
                    to={`/#${section}`}
                    aria-label={`Go to ${section} on home page`}
                >
                    {(children && children) || sectionTitle}
                </Link>
            )}
            {href &&
                (internal ? (
                    <Link to={href} aria-label={`Go to ${dest}`}>
                        {children}
                    </Link>
                ) : (
                    <a href={href} aria-label={`Show ${dest}`}>
                        {children}
                    </a>
                ))}
        </Wrapper>
    );
};

export default ListItem;
