import React, { useState } from 'react';

import styled from 'styled-components';

import { Slider } from '@components/Slider';
import { Section } from '@layouts';
import { device } from '@styles';

import Correct from '../Correct';
import Cross from '../Cross';
import Form from './Form';
import Front from './Front';

const StyledContact = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: var(--bg-gray);
    border-radius: 16px;
    padding: 60px;
    z-index: 50;
    min-width: 250px;

    @media ${device.tablet} {
        flex-direction: column;
        padding: 30px;
    }

    button {
        margin-top: 20px;
    }
`;

const Contact: React.FC = () => {
    const [success, setSuccess] = useState(false);
    return (
        <Section w={550} id="contact">
            <StyledContact>
                <Slider>
                    <Front />
                    <Form setSuccess={setSuccess} />
                    {success ? <Correct /> : <Cross />}
                </Slider>
            </StyledContact>
        </Section>
    );
};

export default Contact;
