import React from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';

import type { FooterContent } from 'src/types';

import ListItem from './ListItem';

const GLink = styled(Link)`
    display: block;
    position: relative;
    font-size: 10px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.1rem;
    padding: 0 8px 0 15px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 5px;
        height: 100%;
        background: var(--orange);
    }
`;

const Container = styled.ul``;

export type ProjectProps = {
    project: FooterContent;
};

const Project: React.FC<ProjectProps> = ({ project }) => {
    const { name, sections } = project;
    return (
        <div>
            <GLink
                to={`/projects/${name}`}
                aria-label={`Go to ${name} project`}
            >
                {name.toUpperCase()}
            </GLink>
            <Container>
                {sections.map((section, index) => (
                    <ListItem key={index} name={name} section={section} />
                ))}
            </Container>
        </div>
    );
};

export default Project;
