import { useMemo } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import type { FooterProjectNode, FooterProjectSectionNode } from 'src/types';

const useProjectsQuery = () => {
    const { projects, sections } = useStaticQuery(graphql`
        {
            projects: allMdx(
                filter: {
                    frontmatter: { featured: { eq: true } }
                    internal: { contentFilePath: { regex: "/projects/" } }
                }
                sort: { fields: frontmatter___date, order: DESC }
            ) {
                nodes {
                    frontmatter {
                        name: slug
                    }
                }
            }
            sections: allMdx(
                filter: {
                    internal: { contentFilePath: { regex: "/sections/" } }
                }
            ) {
                nodes {
                    frontmatter {
                        name
                        project
                    }
                }
            }
        }
    `);

    const content = useMemo(
        () =>
            projects.nodes.map((project: FooterProjectNode) => {
                const projectsContent = {
                    name: project.frontmatter.name,
                    sections: sections.nodes
                        .map((section: FooterProjectSectionNode) => {
                            if (
                                section.frontmatter.project ===
                                project.frontmatter.name
                            ) {
                                return section.frontmatter.name;
                            }
                            return null;
                        })
                        .filter((s: string) => s !== null),
                };

                return projectsContent;
            }),
        [projects, sections]
    );

    return content;
};

export default useProjectsQuery;
