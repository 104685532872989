import React from 'react';

const IconForm = () => (
    <svg
        width="90"
        height="90"
        viewBox="0 0 90 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M65 0H10C4.5 0 0 4.5 0 10V80C0 85.5 4.5 90 10 90H80C85.5 90 90 85.5 90 80V25L65 0ZM20 20H45V30H20V20ZM70 70H20V60H70V70ZM70 50H20V40H70V50ZM60 30V10L80 30H60Z"
            fill="white"
        />
    </svg>
);

export default IconForm;
