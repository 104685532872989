import React from 'react';

const CubeOthers = () => (
    <svg
        width="51"
        height="32"
        viewBox="0 0 51 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M38 25.4202V12.7101L50.5 6.61765V12.9202V19.2227L38 25.4202Z"
            fill="#FF9100"
            fillOpacity="0.8"
        />
        <path
            d="M38 25.4202V12.7101L31.75 9.66387L25.5 6.61765V12.9202V19.2227L31.75 22.3214L38 25.4202Z"
            fill="#FFC400"
            fillOpacity="0.8"
        />
        <path
            d="M38 0L25.5 6.61765L31.75 9.66387L38 12.7101L44.25 9.66387L50.5 6.61765L44.25 3.30882L38 0Z"
            fill="#BDBDBD"
            fillOpacity="0.8"
        />
        <path
            d="M25.5 19.2227L19.25 22.584L13 25.8403L19.25 28.8866L25.5 31.9328L31.75 28.8866L38 25.4202L31.75 22.3214L25.5 19.2227Z"
            fill="#FF9100"
            fillOpacity="0.8"
        />
        <path
            d="M13 12.9202L0.5 19.5378L6.64496 22.584L13 25.8403L19.25 22.584L25.5 19.2227L19.25 16.0189L13 12.9202Z"
            fill="#616161"
            fillOpacity="0.8"
        />
    </svg>
);

export default CubeOthers;
