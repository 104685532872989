import React from 'react';

import styled from 'styled-components';

import Project from '@components/Footer/Project';
import { device } from '@styles';
import type { FooterContent } from 'src/types';

const Wrapper = styled.div`
    grid-column: 2 / 5;
    grid-row: 1 / 3;

    @media ${device.tablet} {
        grid-column: 1 / 4;
        grid-row: 2 / 4;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column: 2 / 5;
    margin-bottom: 64px;
`;

export type ProjectsProps = {
    content: FooterContent[];
};

const Projects: React.FC<ProjectsProps> = ({ content }) => (
    <Wrapper>
        <h4>PROJECTS</h4>
        <Container>
            {content.map((project, index) => (
                <Project project={project} key={index} />
            ))}
        </Container>
    </Wrapper>
);

export default Projects;
