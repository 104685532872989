import React from 'react';

import styled from 'styled-components';

import Icon from '@components/Icon';
import { device } from '@styles';
import type { WidgetsProps } from 'src/types';

const Wrapper = styled.ul<{ listGrid: number }>`
    display: flex;
    justify-content: center;
    align-content: start;
    gap: 50px;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 30px 0px;
    padding: 0 50px;

    @media ${device.tablet} {
        gap: 30px;
    }
`;

const Item = styled.li`
    display: flex;
    justify-content: center;

    svg {
        height: 80px;
    }
`;

const Widgets: React.FC<WidgetsProps> = ({ list }) => (
    <Wrapper listGrid={list.length}>
        {list.map((item, index) => (
            <Item key={index}>
                <Icon
                    name={`${item.toString().toLowerCase().replace(/\s/g, '')}`}
                />
            </Item>
        ))}
    </Wrapper>
);

export default Widgets;
