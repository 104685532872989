import {
    useProjectsShortQuery,
    useOtherProjectsShortQuery,
} from '@hooks/queries';
import { ContentProjectShort } from 'src/types';

const useFetchNextProject = (
    projectName: string,
    featured: boolean
): ContentProjectShort => {
    const fetchNextData = (projects: ContentProjectShort[]) => {
        const index = projects.findIndex(
            (item: ContentProjectShort) => item.name === projectName
        );

        if (projects.length - 1 === index) return projects[0];
        return projects[index + 1];
    };

    if (featured) {
        const projects = useProjectsShortQuery();
        return fetchNextData(projects);
    }
    const projects = useOtherProjectsShortQuery();
    return fetchNextData(projects);
};

export default useFetchNextProject;
