import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { useSlider } from '@components/Slider';

const Wrapper = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? 'initial' : 'none')};

    circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 3;
        stroke-miterlimit: 10;
        stroke: #16a085;
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }
    svg {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 3;
        stroke: #16a085;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px #16a085;
        animation: scale 0.3s ease-in-out 0.9s both;
    }
    path {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }
    @keyframes scale {
        0%,
        100% {
            transform: none;
        }
        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }

    h3 {
        text-align: center;
        color: #16a085;
    }
`;

const Correct: React.FC = () => {
    const { step, nextStep } = useSlider();
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (step === 2) {
            setActive(true);
            setTimeout(() => {
                nextStep();
                setActive(false);
            }, 2500);
        }
    }, []);

    return (
        <Wrapper active={active}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                {' '}
                <circle cx="26" cy="26" r="25" fill="none" />{' '}
                <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <h3>Message has been sent</h3>
        </Wrapper>
    );
};

export default Correct;
