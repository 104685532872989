import React from 'react';

const Plus = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" stroke="white"></path>
        <defs></defs>
    </svg>
);

export default Plus;
