import React from 'react';

const IconBlog = () => (
    <svg
        width="72"
        height="90"
        viewBox="0 0 72 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M63 0H9C4.05 0 0 4.05 0 9V81C0 85.95 4.05 90 9 90H63C67.95 90 72 85.95 72 81V9C72 4.05 67.95 0 63 0ZM9 9H31.5V45L20.25 38.25L9 45V9Z"
            fill="white"
        />
    </svg>
);

export default IconBlog;
