import { css } from 'styled-components';

const variables = css`
    :root {
        --white: #fff;
        --light-gray: hsl(0, 0%, 100%, 0.75);
        --gray: hsl(218, 13%, 24%);
        --black: hsl(210, 13%, 6%);
        --blue: hsl(211, 100%, 49%);
        --text-gray: rgb(255, 255, 255, 0.75);
        --orange: hsl(39, 100%, 40%);
        --light-orange: hsl(39, 100%, 50%);
        --dark-orange: hsl(39, 85%, 5%);
        --bg-gray: hsl(206, 14%, 10%);
        --p-color: hsl(217, 16%, 68%);
        --p-color-4: hsl(217, 16%, 68%, 0.4);

        --nav-logo-icons-padding: 10px;
        --nav-padding: 20%;
        --section-padding: 0px 150px;
        --font-sans: 'Lato', system-ui, sans-serif;
        --font-mono: monospace;
        --nav-height: 3.75rem;

        --fs-xs: 0.8125rem;
        --fs-sm: 0.875rem;
        --fs-md: 1rem;
        --fs-lg: 1.125rem;
        --fs-xl: 1.5rem;
        --fs-xxl: 2rem;
    }
`;

export default variables;
