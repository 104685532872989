import type { PrismTheme } from 'prism-react-renderer';

const prismColors = {
    bg: `hsl(220, 13%, 18%)`,
    lineHighlight: `hsl(218, 13%, 24%)`,
    blue: `#5ccfe6`,
    purple: `#c3a6ff`,
    pink: `#ec5990`,
    green: `#bae67e`,
    yellow: `#ffd580`,
    orange: `#ffae57`,
    red: `#ef6b73`,
    grey: `#a2aabc`,
    comment: `#8695b799`,
    white: `#fff`,
};

const theme: PrismTheme = {
    plain: {
        backgroundColor: `${prismColors.bg}`,
        color: `${prismColors.grey}`,
    },
    styles: [
        {
            types: ['comment', 'prolog', 'doctype', 'cdata', 'block-comment'],
            style: {
                color: `${prismColors.comment}`,
            },
        },
        {
            types: ['punctuation'],
            style: {
                color: `${prismColors.grey}`,
            },
        },
        {
            types: [],
            style: {
                color: `${prismColors.pink}`,
            },
        },
        {
            types: ['namespace', 'deleted'],
            style: {
                color: `${prismColors.red}`,
            },
        },
        {
            types: ['class-name', 'constant', 'variable', 'symbol', 'content'],

            style: {
                color: `${prismColors.yellow}`,
            },
        },
        {
            types: ['unit', 'bold'],
            style: {
                color: `${prismColors.white}`,
            },
        },
        {
            types: [
                'attr-name',
                'operator',
                'rule',
                'parameter',
                'template-string',
                'number',
            ],
            style: {
                color: `${prismColors.orange}`,
            },
        },
        {
            types: ['keyword', 'boolean', 'property'],
            style: {
                color: `${prismColors.purple}`,
            },
        },
        {
            types: [
                'tag',
                'selector',
                'important',
                'atrule',
                'function-name',
                'function',
                'method',
                'builtin',
                'entity',
                'url',
            ],
            style: {
                color: `${prismColors.blue} `,
            },
        },
        {
            types: ['string', 'char', 'attr-value', 'regex', 'inserted'],
            style: {
                color: `${prismColors.green}`,
            },
        },
        {
            types: ['important', 'bold'],
            style: {
                fontWeight: '600',
            },
        },
        {
            types: ['italic'],
            style: {
                fontStyle: 'italic',
            },
        },
        {
            types: ['namespace'],
            style: {
                opacity: 0.7,
            },
        },
    ],
};

export default theme;
