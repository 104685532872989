import React, { useMemo } from 'react';

import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { device } from '@styles';
import { TagContent } from 'src/types';

const Wrapper = styled.div<{ sticky: boolean; table: boolean }>`
    display: flex;
    flex-direction: column;

    ${({ sticky }) =>
        sticky &&
        css`
            position: sticky;
            top: 70px;
        `}

    a {
        display: block;
        padding: 0.75rem 1rem;

        @media ${device.laptop} {
            padding: 0.25rem 0.5rem;
        }
    }

    h4 {
        font-size: 18px;
        line-height: 1.5;
    }

    h5 {
        font-size: 16px;
        line-height: 1.5;
    }

    li {
        position: relative;
        :hover {
            p {
                color: var(--white);
            }

            background: var(--bg-gray);

            :before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 5px;
                height: 100%;
                background: var(--orange);
            }
        }
    }

    div {
        padding: 1.5rem 0;
    }

    @media ${device.laptop} {
        h4,
        h5 {
            font-size: 14px;
        }

        p {
            font-size: 12px;
        }
    }
`;

const StyledLink = styled(Link)<{ active?: boolean }>`
    p {
        ${({ active }) => active && 'color: var(--orange)'}
    }
`;

type ListProps = {
    title: string;
    list: TagContent[] | undefined;
    active?: number;
    table?: boolean;
    post?: string;
};

const List: React.FC<ListProps> = ({
    title,
    list,
    table = false,
    active,
    post,
}) => {
    const mapListItems = useMemo(
        () =>
            list?.map((item, index) => {
                const { value, count } = item;
                return (
                    <li key={`${item}-${index}`}>
                        {count ? (
                            <StyledLink
                                active={active === index}
                                to={`/blog/tags/${value.replace(/\s/g, '')}`}
                            >
                                <p>{`${value} (${count})`}</p>
                            </StyledLink>
                        ) : (
                            <StyledLink
                                active={active === index}
                                to={`/blog/${post}/#${value}`}
                            >
                                <p>{value}</p>
                            </StyledLink>
                        )}
                    </li>
                );
            }),
        [list]
    );
    return (
        <Wrapper sticky={table} table={table}>
            <h4>{title}</h4>
            <div>
                {!table && (
                    <Link to="/blog">
                        <h5>View all</h5>
                    </Link>
                )}
                <ul>{mapListItems}</ul>
            </div>
        </Wrapper>
    );
};

export default List;
