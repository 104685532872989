const preToCodeBlock = (preProps: any) => {
    if (
        // children is code element
        preProps.children &&
        // code props
        preProps.children.props &&
        // if children is actually a <code>
        preProps.children.props.children
    ) {
        // we have a <pre><code> situation
        const {
            children: codeString,
            className = '',
            ...props
        } = preProps.children.props;

        const match = className.match(/language-([\0-\uFFFF]*)/);

        return {
            codeString: codeString.trim(),
            className,
            language: match != null ? match[1] : '',
            ...props,
        };
    }
    return undefined;
};

export default preToCodeBlock;
