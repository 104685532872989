import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import Button from '@components/Button';
import Icon from '@components/Icon';
import List from '@components/List';
import Paragraph from '@components/Paragraph';
import { device } from '@styles';
import type { ProjectNode } from 'src/types';

const Wrapper = styled.li`
    margin: 100px 0px;
    padding: 30px;
    border-radius: 15px;
    position: relative;
    transition: background 0.2s ease-out;

    &:hover {
        background: var(--bg-gray);
    }

    @media ${device.tablet} {
        margin: 50px 0;
        padding: 0;
    }
`;

const Content = styled.div`
    min-width: 450px;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > div:not(:last-of-type) {
        margin-bottom: 24px;
    }

    & > svg {
        right: 0;
        bottom: -40px;
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: translateY(100%);
        opacity: 0;
        z-index: -1;

        @media ${device.laptop} {
            display: none;
        }
    }

    & > p {
        margin-bottom: 16px;
    }

    @media ${device.laptop} {
        min-width: auto;
    }

    @media ${device.laptop} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        grid-column: 1 / -1;
        padding: 20px;
    }
`;

const Container = styled.div<{ odd: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 75px;

    & > *:first-child {
        order: ${({ odd }) => (odd ? '1' : '2')};
    }

    & > *:nth-child(2) {
        order: ${({ odd }) => (odd ? '2' : '1')};
    }

    p,
    h3 {
        transition: all 0.25s;
    }

    .img,
    & > svg {
        background-size: cover;
        border-radius: 5px;
        width: 100%;
        height: auto;
        object-fit: cover;
        grid-row: 1;

        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

        @media ${device.laptop} {
            grid-column: 1 / -1;
            opacity: 0.25;
            height: 100%;
        }
    }

    & > svg {
        padding: 100px;
        border: 1px solid var(--gray);
    }

    @media (max-width: 1000px) {
        column-gap: 30px;
        background: var(--bg-gray);
    }

    @media ${device.tablet} {
        grid-template-columns: 1fr;
        text-align: left;
    }
`;

const Buttons = styled.div`
    display: flex;
`;

export type ProjectProps = {
    project: ProjectNode;
    odd: boolean;
    index: number;
    refs: React.MutableRefObject<HTMLElement[] | null[]>;
};

const Project: React.FC<ProjectProps> = ({ project, odd, index, refs }) => {
    const { name, description, quickDesc, tech, img, links, linkIcons } =
        project;
    const image = getImage(img);

    return (
        <Wrapper
            key={index}
            ref={(el: HTMLElement | null) => {
                // eslint-disable-next-line no-param-reassign
                refs.current[index] = el;
            }}
        >
            <Container odd={odd}>
                {image ? (
                    <GatsbyImage image={image} alt={name} className="img" />
                ) : (
                    <Icon name="logo" />
                )}
                <Content>
                    <Paragraph
                        data={{
                            overline: quickDesc,
                            href: `/projects/${name}`,
                            header: name,
                            text: description,
                        }}
                        styles={{ width: 'restrained' }}
                    />
                    <List widgets list={tech} />
                    <Buttons>
                        {links &&
                            links.map((item, i) => (
                                <Button
                                    key={i}
                                    square
                                    icon={linkIcons && linkIcons[i]}
                                    primary={
                                        linkIcons && linkIcons[i] === 'external'
                                    }
                                    href={item}
                                >
                                    {linkIcons && linkIcons[i] === 'github'
                                        ? 'Open in github'
                                        : 'Live server'}
                                </Button>
                            ))}
                    </Buttons>
                </Content>
            </Container>
        </Wrapper>
    );
};

export default Project;
