import React from 'react';

const IconArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="none">
        <path d="M1 1L5 5L9 1" stroke="white" strokeWidth={2}></path>
        <defs></defs>
    </svg>
);

export default IconArrow;
