import { css } from 'styled-components';

const TransitionStyle = css`
    .enter {
        opacity: 0;
        transform: translateY(0px);
        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .enter-active {
        opacity: 1;
        transform: translateY(20px);
        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }
`;

export default TransitionStyle;
