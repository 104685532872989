import { graphql, useStaticQuery } from 'gatsby';

import type { ContentProjectShort } from 'src/types';

const useProjectsShortQuery = () => {
    const { projects } = useStaticQuery(graphql`
        {
            projects: allMdx(
                filter: {
                    frontmatter: { featured: { eq: true } }
                    internal: { contentFilePath: { regex: "/projects/" } }
                }
                sort: { fields: [frontmatter___date], order: DESC }
            ) {
                nodes {
                    frontmatter {
                        quickDesc
                        name: slug
                        icon
                        slug
                    }
                }
            }
        }
    `);

    const content: ContentProjectShort[] = projects.nodes.map(
        (node: { frontmatter: ContentProjectShort }) => node.frontmatter
    );

    return content;
};

export default useProjectsShortQuery;
